
import { Modal } from "reactstrap";

const ModalCantFind = ({showModal, toggleModal}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Can't Find Your Provider?</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <p className="mb-0">If you can't find your auto insurance provider in the list that's okay. It just means that you'll need to contact them directly or download your policy either online or via your providers mobile app.</p>
        </div>

        <div className="modal-footer text-right">
            <button className="btn btn-warning" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>

)

export default ModalCantFind;
