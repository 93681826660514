/*
Documentation

this page runs the routing for verifying a user creating mx and pinwheel reports

*/

import keys from 'keys';
import { Redirect } from 'react-router-dom';
import { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';

import { Col, Container, Row } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import { getUrlParameter } from 'utils/urls';

import VerifyCompany from './Verification/Company'

import Fize from './Fize'

import UtilityAccessDenied from './Utility/AccessDenied'
import UtilityError from './Utility/Error'
import UtilityCloseIframe from './Utility/CloseIframe'

import _contacts from '_functions/contacts'
import _companies from '_functions/companies';


const Reports = ({match}) => {

    const [parentDomain,            setParentDomain]              = useState(false);
    const [isIframe,                setIsIframe]                  = useState(false);
    const [err,                     setErr]                       = useState(false);
    const [view,                    setView]                      = useState('VerifyCompany');
    const [identifier,              setIdentifier]                = useState(null);
    const [foundContact,            setFoundContact]              = useState({});
    const [foundCompany,            setFoundCompany]              = useState({});
    const [accessDenied,            setAccessDenied]              = useState(false);
   
    const onSetView = useCallback((view) => {
        setView(view)
    },  [])


    const onCompanyVerified = useCallback(async (contact) => {
        if(contact) setFoundContact(contact);
        return onSetView('Fize');
    }, [onSetView])

    const onMessageReceived = useCallback((message) => {
        if(message && message.data && message.data.type === 'zapReportsAutoPolicy') {
            if(message.data.event === 'onLoad') {
                setParentDomain(message.data.data.domain)
            }
        }
    }, [])

    useEffect(() => {
        if(window.location !== window.parent.location) {
            setIsIframe(true);
            window.addEventListener("message", onMessageReceived)
        } 

        return () => {
            if(window.location !== window.parent.location) {
                setIsIframe(true);
                window.removeEventListener("message", onMessageReceived)
            } 
        }

    }, [onMessageReceived])
   
    useEffect(() => {

        const fetchCompany = async () => {

            let name = match.params.company_name;
            if(name) name = name.replace(/\+/g, ' ')

            const company = await _companies.findByName(name);

            if(!company.data) return setAccessDenied(true)

            if(company.data.branding && company.data.branding.color) {
                document.body.style.background = company.data.branding.color
            }

            const identifier = getUrlParameter('i')

            if(identifier) {
                const verified = await _contacts.findOrCreate({
                    identifier, 
                    company: company.data._id 
                })

                if(!verified.data) return setErr(true)
                setFoundContact(verified.data);
                setIdentifier(identifier)

            }
            
            setFoundCompany(company.data)

            if(window.location !== window.parent.location) {    
                // if we are an iframe return access denied if the docmain has not been whitelisted
                // example whitelist: http://localhost:3000/
                var url = (window.location !== window.parent.location) ? document.referrer: document.location.href;
                if(!company.data.authorized_embed_domain || (company.data.authorized_embed_domain && !company.data.authorized_embed_domain.includes(url)) ) {
                    return setAccessDenied(true)
                }
            } 

        }

        fetchCompany()

    }, [match.params.company_name])

    if(accessDenied) return  <UtilityAccessDenied isIframe={isIframe} parentDomain={parentDomain} />

    if(foundCompany === 'not found') return <Redirect to="/" />;
    if(!foundCompany._id) return <div className="py-6"><Circle /></div>

    const logo = foundCompany.branding && foundCompany.branding.logo ? foundCompany.branding.logo : keys.LOGO;
    const companyNameColor = foundCompany.branding && foundCompany.branding.name_color ? foundCompany.branding.name_color : '#525f7f';
    const companyLogoWidth = foundCompany.branding && foundCompany.branding.logo_width ? parseInt(foundCompany.branding.logo_width) : 300;
    const hideName = foundCompany.branding && foundCompany.branding.hide_name ? foundCompany.branding.hide_name : false;

    return (
        <>

			<Helmet>
				<title>{foundCompany ? `${foundCompany.name} - Powered By Zap Reports` : 'Powered By Zap Reports'}</title>
				<meta name="description" content={foundCompany ? `${foundCompany.name} - Powered By Zap Reports` : 'Zap Reports'} />
			</Helmet>

            <UtilityCloseIframe isIframe={isIframe} parentDomain={parentDomain} />

            <div className="header  pt-5 pb-6 pb-md-8 py-lg-8 pt-lg-9">
                <Container className="pb-4">
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <img style={{width:  companyLogoWidth, position: 'relative',}} alt="..." className={!hideName ? "mb-4" : null}  src={logo} />
                                {!hideName ? (
                                    <h3 className="display-4 mb-0" style={{color: companyNameColor}}>{foundCompany.name}</h3>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            {accessDenied ? (
                <UtilityAccessDenied isIframe={isIframe} parentDomain={parentDomain} />
            ) : err ? (
                <UtilityError />
            ) : view === 'VerifyCompany' ? (
                <VerifyCompany 
                    onCompanyVerified={onCompanyVerified} 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    companyNameColor={companyNameColor}
                    identifier={identifier}
                />
            ) : view === 'Fize' ? (
                <Fize 
                    foundCompany={foundCompany}
                    foundContact={foundContact}
                    setView={onSetView}
                    companyNameColor={companyNameColor}
                    onSetView={onSetView}
                    isIframe={isIframe}
                    parentDomain={parentDomain}
                />
            ) : null}

		</>

    )
}

export default Reports