import Reports from '../pages/Reports';
import Home from '../pages/Home/index';

const routes = [
    {
        path: ":company_name",
        component: Reports,
    },
    {
        path: "",
        component: Home,
    },
]

export default routes;