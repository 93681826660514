import apiWorker from '../apiWorker'

const integrations = {

    yodlee: {
        tokens: {
            create : (data) => apiWorker('post',  `/v1/integrations/yodlee/tokens/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
        },
        accounts: {
            find   : (data) => apiWorker('get',     `/v1/integrations/yodlee/accounts/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
            delete : (data) => apiWorker('delete',  `/v1/integrations/yodlee/accounts/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
        },
        providers: {
            search : (string) => apiWorker('get',  `/v1/integrations/agg2/providers/search?search=${string}`, null, true),

        }
    },

    
    mx: {
        request_url : (data) => apiWorker('post',  `/v1/integrations/mx/connect/request_url/${data.contact_id}/${data.company_id}/${data.access_code}`, data, true),
        members: {
            list : (data)   =>  apiWorker('get',  `/v1/integrations/mx/members/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
            delete : (data) =>  apiWorker('delete',  `/v1/integrations/mx/members/${data.contact_id}/${data.company_id}/${data.access_code}/${data.member_id}`),
            read : (data) =>  apiWorker('get',  `/v1/integrations/mx/members/${data.contact_id}/${data.company_id}/${data.access_code}/${data.member_id}`),
            extend : (data) =>  apiWorker('post',  `/v1/integrations/mx/extend/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
            identity : (data) =>  apiWorker('post',  `/v1/integrations/mx/identity/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
            statements : (data) =>  apiWorker('post',  `/v1/integrations/mx/statements/${data.contact_id}/${data.company_id}/${data.access_code}`, data),
        },
        institutions: {
            search : (search) =>  apiWorker('get',  `/v1/integrations/agg1/institutions/search?search=${search}`, null, true),
        }

        
    },

    pinwheel: {
        link_token : (data) =>  apiWorker('get',  `/v1/integrations/pinwheel/token/${data.contact_id}/${data.company_id}/${data.access_code}?account_id=${data.account_id}&employer_id=${data.employer_id}`, null, true),
        accounts: {
            delete : (data) =>  apiWorker('delete',  `/v1/integrations/pinwheel/accounts/${data.account_id}/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
            list : (data) =>  apiWorker('get',  `/v1/integrations/pinwheel/accounts/${data.contact_id}/${data.company_id}/${data.access_code}`, null, true),
        },
        employers: {
            search : (string) =>  apiWorker('get',  `/v1/integrations/agg3/search?q=${string}`, null, true),
        }

    },

    fize: {
        pullReport : (data) =>  apiWorker('post',  `/v1/integrations/fize/pull_report/documents`, data, true),
    }

}

export default integrations;