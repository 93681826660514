import LOGO from 'assets/img/brand/ZapReportsCut.png'

let keys = {
    LOGO,
    COMPANY: 'Zap Reports',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'policy.zapreports.com') {

    keys = {

        ...keys,
        
        API_URL                   : 'https://api.zapreports.com',
        SITE_URL                  : 'https://zapreports.com',
        SYSTEM_API_KEY            : 'architeck-58163098451',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        FAST_LINK_URL             : 'https://fl4.prod.yodlee.com/authenticate/USDevexProd3-263/fastlink?channelAppName=usdevexprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',

        INSTITUTION_VENMO         : '77733',
        INSTITUTION_CASH_APP      : '7df5cd59-3909-4db9-968e-914917bdba87',
        INSTITUTION_PAYPAL        : 'paypal',
        INSTITUTION_ZELLE         : '4cc276b1-6a95-4621-85be-9bfff47d7c7d',
        INSTITUTION_CHIME         : 'b42f4be5-d729-031c-3123-fcf41441ff53',

        BLUE_FIN_API_URL          : 'https://secure.payconex.net',
        BLUE_FIN_ACCOUNT_ID       : '120615600564',

        FIZE_CLIENT_ID            : 'sTh9wgGr0KuscbUZkOgUpSdsVOiMqkuj',
    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        API_URL                   : 'https://staging.api.zapreports.com',
        SITE_URL                  : 'https://staging.zapreports.com',
        SYSTEM_API_KEY            : 'architeck-946154718',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        FAST_LINK_URL             : 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd3-3/fastlink?channelAppName=usdevexpreprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',

        INSTITUTION_VENMO         : 'mx_bank_oauth',
        INSTITUTION_CASH_APP      : 'mx_bank_oauth',
        INSTITUTION_PAYPAL        : 'mx_bank_oauth',
        INSTITUTION_ZELLE         : 'mx_bank_oauth',
        INSTITUTION_CHIME         : 'mx_bank_oauth',

        BLUE_FIN_API_URL          : 'https://cert.payconex.net',
        BLUE_FIN_ACCOUNT_ID       : '220614985101',

        FIZE_CLIENT_ID            : '4uJ2dSbpysFIoq55rlJo76rli3KxfQl0',
    }

//using development keys
} else {

    keys = {

        ...keys,

        API_URL                   : 'http://localhost:5004',
        SITE_URL                  : 'http://localhost:3003',
        SYSTEM_API_KEY            : 'architeck-',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        FAST_LINK_URL             : 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd3-3/fastlink?channelAppName=usdevexpreprod3',
        FAST_LINK_CONFIGURATION   : 'Config1',

        INSTITUTION_VENMO         : 'mx_bank_oauth',
        INSTITUTION_CASH_APP      : 'mx_bank_oauth',
        INSTITUTION_PAYPAL        : 'mx_bank_oauth',
        INSTITUTION_ZELLE         : 'mx_bank_oauth',
        INSTITUTION_CHIME         : 'mx_bank_oauth',

        BLUE_FIN_API_URL          : 'https://cert.payconex.net',
        BLUE_FIN_ACCOUNT_ID       : '220614985101',
        
        FIZE_CLIENT_ID            : '4uJ2dSbpysFIoq55rlJo76rli3KxfQl0',
    }

}

export default keys
