import PropTypes from 'prop-types'
import { Modal } from "reactstrap";

const ModalTerms = ({showModal, toggleModal}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >

        <div className="modal-header">
            <h5 className="modal-title">Terms Of Service</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">
            <ol className="pl-4">
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Provide Accurate Information.</b> You represent and agree that all information you provide to us in connection
                    with the Services is accurate, current, and complete. You agree not to misrepresent your identity or account
                    information. You agree to keep account information secure, up to date and accurate. You represent that you are a
                    legal owner, or an authorized user, of the accounts at third party sites which you include or access through the
                    Services, and that you have the authority to (i) designate us and our service providers as your agent, (ii) use the
                    Services, and (iii) give us and our service providers the passwords, usernames, and all other information you
                    provide.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Content You Provide.</b> Your use of the Services is your authorization for Financial Institution or its service
                    providers, as your agent, to access third party sites which you designate in order to retrieve information. You are
                    licensing to Financial Institution and its service providers any information, data, passwords, usernames, PINS,
                    personally identifiable information or other content you provide through the Services. You authorize us or our
                    service providers to use any information, data, passwords, usernames, PINS, personally identifiable information
                    or other content you provide through the Services or that we or our service providers retrieve on your behalf for
                    purposes of providing the Services, to offer products and services, and for other permissible business purposes.
                    Except as otherwise provided herein, we or our service providers may store, use, change, or display such
                    information or create new content using such information.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Authority to Access Information.</b> Unless and until this User agreement is terminated, you grant Financial
                    Institution and its service providers the right to access information at third-party sites on your behalf. Third-party
                    sites shall be entitled to rely on the authorizations granted by you or through your account. For all purposes
                    hereof, you hereby grant Financial Institution and its service providers the right to access third-party sites to
                    retrieve information, use such information, as described herein, with the full power and authority to do and
                    perform each and every act and thing required and necessary to be done in connection with such activities, as fully
                    to all intents and purposes as you might or could do in person. Upon notice to Financial Institution, you may (i)
                    revoke Financial Institution’s right to access information at third party sites on your behalf, or (ii) subject to
                    Section 7, request deletion of information collected from third party sites. You understand and agree that the Services are not sponsored or endorsed by any third-party site. YOU ACKNOWLEDGE AND AGREE THAT WHEN FINANCIAL INSTITUTION OR ITS SERVICE PROVIDERS ACCESS AND RETRIEVE
                    INFORMATION FROM THIRD-PARTY SITES, THEY ARE ACTING AT YOUR REQUEST AND WITH
                    YOUR PERMISSION AND AUTHORIZATION, AND NOT ON BEHALF OF THE THIRD-PARTY SITES.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Third Party Accounts.</b> With respect to any third-party sites we may enable you to access through the
                    Services or with respect to any non- Financial Institution accounts you include in the Services, you agree to the
                    following:

                    <ol>
                        <li>
                            You are responsible for all fees charged by the third party in connection with any non- Financial
                            Institution accounts and transactions. You agree to comply with the terms and conditions of those accounts
                            and agree that this User agreement does not amend any of those terms and conditions. If you have a dispute or
                            question about any transaction on a non- Financial Institution account, you agree to direct these to the account
                            provider.
                        </li>
                    <li>
                        Any links to third party sites that we may provide are for your convenience only, and Financial Institution
                        and its service providers do not sponsor or endorse those sites. Any third-party services, which you may be
                        able to access through the Services, are services of the listed institutions. We nor our service providers have
                        responsibility for any transactions and inquiries you initiate at third party sites. The third-party sites you select
                        are solely responsible for their services to you. We nor our service providers are liable for any damages or
                        costs of any type arising out of or in any way connected with your use of the services of those third parties.
                    </li>
                    </ol>
                
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Limitations of Services.</b> When using the Services, you may incur technical or other difficulties. We nor our
                    service providers are responsible for any technical or other difficulties or any resulting damages that you may
                    incur. Any information displayed or provided as part of the Services is for informational purposes only, does not
                    represent an official record of your account, may not reflect your most recent transactions, and should not be
                    relied on for transactional purposes. We and our service providers reserve the right to change, suspend or
                    discontinue any or all of the Services at any time without prior notice. In the event that Services are discontinued,
                    your information shall be retained in accordance with this Agreement and our privacy policies.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Acceptance of User Agreement and Changes.</b> Your use of the Services constitutes your acceptance of this
                    User agreement. This User agreement is subject to change from time to time. We will notify you of any material
                    change via e-mail or on our website by providing a link to the revised User agreement. Your continued use will
                    indicate your acceptance of the revised User agreement. The licenses, user obligations, and authorizations
                    described herein are ongoing.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Aggregated Data.</b> Anonymous, aggregate information, comprising financial account balances, other
                    financial account data, or other available data that is collected through your use of the Services, may be used by us
                    and our service providers to conduct certain analytical research, performance tracking and benchmarking. Our
                    service providers may publish summary or aggregate results relating to metrics comprised of research data, from
                    time to time, and distribute or license such anonymous, aggregated research data for any purpose, including but
                    not limited to, helping to improve products and services and assisting in troubleshooting and technical support.
                    Your personally identifiable information will not be shared with or sold to third parties.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Ownership.</b> You agree that Financial Institution and its service providers, as applicable, retain all ownership
                    and proprietary rights in the Services, associated content, technology, mobile applications and websites.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>User Conduct.</b> You agree not to use the Services or the content or information delivered through the Services
                    in any way that would: (a) be fraudulent or involve the sale of counterfeit or stolen items, including but not
                    limited to use of the Services to impersonate another person or entity; (b) violate any law, statute, ordinance or
                    regulation (including without limitation those governing export control, consumer protection, unfair competition,
                    anti-discrimination or false advertising); (c) create liability for Financial Institution or its service provider or cause
                    Financial Institution to lose the services of our service providers; (d) access the information and content
                    programmatically by macro or other automated means; or (e) use the Services in such a manner as to gain
                    unauthorized entry or access to computer systems.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Indemnification.</b> You agree to defend, indemnify and hold harmless Financial Institution, its third party
                    service providers and their officers, directors, employees and agents from and against any and all third party
                    claims, liabilities, damages, losses or expenses, including settlement amounts and reasonable attorneys&#39; fees and
                    costs, arising out of or in any way connected with your access to or use of the Services, your violation of these
                    terms or your infringement, or infringement by any other user of your account, of any intellectual property or
                    other right of anyone.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Disclaimer.</b> The Services are not intended to provide legal, tax or financial advice. The Services, or certain
                    portions and/or functionalities thereof, are provided as strictly educational in nature and are provided with the
                    understanding that neither Financial Institutions nor its third-party providers are engaged in rendering accounting,
                    investment, tax, legal, or other professional services. If legal or other professional advice including financial, is
                    required, the services of a competent professional person should be sought. Financial Institution and its third-party
                    providers specifically disclaim any liability, loss, or risk which is incurred as consequence, directly or indirectly,
                    of the use and application of any of the content on this site. Further, Financial Institution and its third-party
                    providers are not responsible for any credit, insurance, employment or investment decisions or any damages or
                    other losses resulting from decisions that arise in any way from the use of the Services or any materials or
                    information accessible through it. Past performance does not guarantee future results. Financial Institution and its
                    third-party providers do not warrant that the Services comply with the requirements of the FINRA or those of any
                    other organization anywhere in the world.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>DISCLAIMER OF WARRANTIES.</b> YOU AGREE YOUR USE OF THE SERVICES AND ALL
                    INFORMATION AND CONTENT (INCLUDING THAT OF THIRD PARTIES) IS AT YOUR RISK AND IS
                    PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. WE, AND OUR SERVICE PROVIDERS,
                    DISCLAIM ALL WARRANTIES OF ANY KIND AS TO THE USE OF THE SERVICES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. WE, AND
                    OUR SERVICE PROVIDERS, MAKE NO WARRANTY THAT THE SERVICES (i) WILL MEET YOUR
                    REQUIREMENTS, (ii) WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE
                    RESULTS THAT MAY BE OBTAINED FROM THE SERVICES WILL BE ACCURATE OR RELIABLE, (iv)
                    THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED
                    BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS, OR (v) ANY ERRORS IN
                    THE SERVICES OR TECHNOLOGY WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR
                    OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN
                    DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                    COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH
                    MATERIAL. WE, ON BEHALF OF OURSELVES AND ALL THIRD PARTY DATA PROVIDERS,
                    EXPRESSLY DISCLAIMS ANY TYPE OF REPRESENTATION OR WARRANTY REGARDING THE
                    AVAILABILITY OR RESPONSE TIME OF THE SERVICE OR CONTENT OR INFORMATION OBTAINED
                    THROUGH THE SERVICE OR THAT SUCH ACCESS WILL BE UNINTERRUPTED OR ERROR-FREE
                    AND, EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, EXPRESSLY DISCLAIMS THE ACCURACY,
                    COMPLETENESS AND CURRENCY OF ALL INFORMATION COLLECTED ON YOUR BEHALF. NO
                    ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM FINANCIAL
                    INSTITUTION OR ITS SERVICE PROVIDERS THROUGH OR FROM THE SERVICES WILL CREATE
                    ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>LIMITATION OF LIABILITY.</b> YOU AGREE THAT FINANCIAL INSTITUTION AND ITS THIRD
                    PARTY SERVICE PROVIDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO
                    DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER LOSSES, EVEN IF WE HAVE
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM (i) THE USE OR THE
                    INABILITY TO USE THE SERVICES AT OUR WEBSITE/MOBILE APPLICATION OR OF ANY THIRD
                    PARTY ACCOUNT PROVIDER&#39;S WEBSITE/MOBILE APPLICATION; (ii) THE COST OF GETTING
                    SUBSTITUTE GOODS AND SERVICES, (iii) ANY PRODUCTS, DATA, INFORMATION OR SERVICES
                    PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO,
                    THROUGH OR FROM THE SERVICES, (iv) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                    TRANSMISSION OR DATA, (v) STATEMENTS OR CONDUCT OF ANYONE ON THE SERVICES, (vi)
                    THE USE, INABILITY TO USE, UNAUTHORIZED USE, PERFORMANCE OR NON-PERFORMANCE OF
                    ANY THIRD PARTY ACCOUNT PROVIDER SITE, EVEN IF THE PROVIDER HAS BEEN ADVISED
                    PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES, OR (vii) ANY OTHER MATTER RELATING
                    TO THE SERVICES.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>WAIVER OF JURY TRIAL AND CLASS ACTION.</b> You agree that, with respect to any dispute with us
                    or our service providers, arising out of or relating to your use of the Services or these terms: (i) YOU ARE
                    GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY; and (ii) YOU ARE GIVING UP YOUR RIGHT
                    TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER
                    REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS,
                    IN ANY LAWSUIT INVOLVING SUCH DISPUTE.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Export Restrictions.</b> You acknowledge that the Services and any software underlying such Services are
                    subject to the U.S. Export Administration Regulations (15 CFR, Chapter VII) and that you will comply with these
                    regulations. You will not export or re-export the software or Services, directly or indirectly, to: (1) any countries
                    that are subject to U.S. export restrictions; (2) any end user who has been prohibited from participating in U.S.
                    export transactions by any federal agency of the U.S. government; or (3) any end user who you know or have
                    reason to know will utilize them in the design, development or production of nuclear, chemical or biological
                    weapons. You further acknowledge that the Services may include technical data subject to export and re-export
                    restrictions imposed by U.S. law.
                </li>
                <li style={{fontSize: 12, marginBottom: 20}}>
                    <b>Other Terms.</b> You may not assign this User agreement. A determination that any provision of this User
                    agreement is unenforceable or invalid shall not render any other provision of this User agreement unenforceable
                    or invalid.
                </li>
            </ol>

        </div>

        <div className="modal-footer text-right">
            <button className="btn btn-success" onClick={toggleModal}>
                Close
            </button>
        </div>

    </Modal>
    )

ModalTerms.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalTerms
