/*
Documentation

this component renders the connect widget with MX

*/

import keys from 'keys';
import { useState, useCallback, useEffect } from "react";

import { Card, CardFooter, CardHeader, CardTitle, Col, Container,  Row, Progress, CardBody } from "reactstrap";

import Circle from 'components/markup/loading/Circle'
import Dots from 'components/markup/loading/Dots'
import FizeIcon from '../_images/fize.svg'
import ModalCantFind from './ModalCantFind'

import _integrations from '_functions/integrations'

import ModalToggler from 'components/functional/modals/Toggler'

import fileDownloader from 'js-file-download';
import Axios from 'axios';

import { toggleAlertBS } from 'store/functions/system/system';

const Fize = ({ foundContact, foundCompany, setView, companyNameColor, isIframe, parentDomain }) => {

    const [err, setErr] = useState(false);
    const [initalLoad, setInitialLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [wasClosed, setWasClosed] = useState(false);
    const [carrier, setCarrier] = useState('');
    const [handler, setHandler] = useState(false);
    const [results, setResults] = useState([]);

    const onDownload = useCallback(async (company, document, password) => {
    
        const result = await Axios({
            method: 'get',
            url: keys.API_URL + `/v1/api/documents/${document}/download/${company}/${password}`,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })
    
        if(!result.headers['filename']) {
            return toggleAlertBS('info', 'The link to this document has expired.')
        }
    
        fileDownloader(result.data, result.headers.filename) 

    }, [])

    const launchFize = useCallback(() => {

        var configObj = {
            config: {
                placement_id: 1,
                logo_url:'https://reports.zapreports.com/fizeLogo.png',
                display_name: foundCompany.name,
                policy_data_config: {
                    auto_policy: true,
                    home_policy: false,
                },
                theme_config: {
                    theme_color: "#2CC4E4",
                    primary_text_color: "#525f7f",
                    secondary_text_color: "#32325d",
                    primary_header_color: "#525f7f",
                    secondary_header_color: "#2dce89",
                    border_color: "#e6e5e3",
                    hover_bg_color: "#ebdec5",
                    font_family: "Arial",
                },
    
            },
        };

        if(handler) return handler.launch(configObj)

        var _handler = window.FIZE.configure({ 
            client_id: keys.FIZE_CLIENT_ID,
            onSuccess: async function(metadata) {
                const { connectionId } = metadata;

                const modal = document.getElementById('fize-widget-container');
                modal.parentNode.removeChild(modal);
                setWasClosed(true)

                setLoading(true)
                const data = await _integrations.fize.pullReport({
                    contact_id: foundContact._id,
                    company_id: foundCompany._id,
                    connection_id: connectionId
                })
                setLoading(false)

                if(data.data) {

                    if(isIframe && parentDomain) {
                        let payload = [];
                        data.data.forEach(result => {
                            payload.push({_id: result._id, name: result.name})
                        })
                        window.parent.postMessage({
                            type: 'zapReportsAutoPolicy',
                            event: 'onConnect',
                            data: payload
                        }, parentDomain)
                    }

                    setResults(data.data)
                    setCarrier(data.carrier)
                } else {
                    setErr(true);
                }

            },
            onClose: () => {
                setWasClosed(true)
            }
        });
       
        _handler.launch(configObj)
        setHandler(_handler)
     
    }, [foundContact._id, foundCompany._id, foundCompany.name, handler, isIframe, parentDomain])

    useEffect(() => {
        launchFize()
        setTimeout(() => {
            setInitialLoad(true)
        }, 3000)
        // eslint-disable-next-line
    }, [])

    if(err) return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
                    <Card className="bg-white border-0 mb-0 position-relative" >
                        <CardHeader>
                            <CardTitle className="mb-0"><i className="fas fa-info-circle text-info mr-2 " /> An Unexpected Error Occurred</CardTitle>
                        </CardHeader>
                        <CardFooter>
                            <p>Something went wrong linking your auto policy, we have been notified of the problem and are working hard to fix this issue. </p>
                            <p className="mb-0">If you wish to try again please refresh your page and try to re-link your account.</p>
                        </CardFooter>
                    </Card>

                </Col>
            </Row>
        </Container>
    )

    if(loading) return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
                    <Card className="bg-white border-0 mb-0 position-relative" >
                        <CardBody>
                            <div>  
                                <div className="text-center mb-0"><i className="fas fa-check bg-success text-white " style={{borderRadius: '100%', padding: '8px'}} /></div>
                                <p className='text-center font-weight-bold my-3 mb-0'>Downloading Your Policy, This Should Take About 30 Seconds</p>
                                <div className='text-center mt--4'><Dots /></div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Container>
    )
    if(!initalLoad) return (
        <Container className="mt--8 pb-5">
            <Circle />
        </Container>
    )

    if(!wasClosed) return <div />

    if(results && results.length) return (
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={FizeIcon} className="bg-white flow-icon" alt="..." />

                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">All Set!</CardTitle>
                        </CardHeader>

                        <CardHeader>
                            <p>You have successfully connected your Auto Policy and can download your documents here.</p>
                            <p>Please make sure to keep a copy of your documents before leaving this page as they will not be shown again.</p>
                        </CardHeader>
                            {results.map((result, i) => (
                                <CardFooter key={i}>
                                    <Row >
                                        <Col xs={6} className="align-self-center">
                                            <p className="text-sm mb-0 font-weight-bold text-dark"><i className="fas fa-check text-success mr-2 text-capitalize" />{carrier} - {result.name}</p>
                                            <p className="text-sm mb-0">Ready For Download</p>
                                        </Col>
                                        <Col xs={6} className="align-self-center text-right">
                                            <button onClick={() => onDownload(foundCompany._id, result._id, result.password)} className="btn btn-success"><i className="fas fa-download mr-2 " /> Download</button>
                                        </Col>
                                    </Row>
                                </CardFooter>

                            ))}

                    </Card>
                    
                </Col>
            </Row>
        </Container>

    )
    
    return (

        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <Card className="bg-white border-0 mb-0 position-relative" >

                        <img src={FizeIcon} className="bg-white flow-icon" alt="..." />

                        <CardHeader>
                            <CardTitle className="mb-0 flow-icon-header">Auto Insurance</CardTitle>
                            <div className="progress-wrapper pt-2">
                                <div className="progress-success">
                                    <div className="progress-percentage">
                                        <span style={{position: 'relative', top: -3}}>Step 1/1</span>
                                    </div>
                                </div>
                                <Progress max="100" value={50} color="success" />
                            </div>
                        </CardHeader>

                        <CardHeader>
                            <p>Alright, time to connect your auto insurance policy.</p>
                            <p> Click the <b className="text-info">BLUE</b> button below to search for your current auto insurance provider.</p>
                            <p className=" mb-0">You login will be your email and password that you would normally use to log into your policy providers website or app.</p>
                        </CardHeader>

                        <CardFooter>
                            <button  onClick={() => launchFize()} className=" btn btn-info btn-block my-2 btn-lg">
                                <i className="fas fa-link mr-2 " /> 
                                Link Your Auto Policy
                            </button>
                        </CardFooter>

                    </Card>

                    <p 
                        className="text-sm mb-0 text-center mt-4 font-weight-bold cursor-pointer" 
                        onClick={() => setView(foundCompany.accept_auto_policies ? 'Fize' :  foundCompany.accept_employment ? 'PinwheelSideHustles' : 'MXApps')}
                        style={{color: companyNameColor}}
                    >
                        <ModalToggler component={ModalCantFind}>
                            <i className="fas fa-info-circle text-info mr-2 " />{' '}
                            I Cant Find My Provider
                        </ModalToggler>
                    </p>

                </Col>
            </Row>
        </Container>

    )
}

export default Fize