import { Helmet } from 'react-helmet';
import { Col, Container, Row } from "reactstrap";

import UtilityCloseIframe from './CloseIframe';

const UtilityAccessDenied = ({isIframe, parentDomain}) => (
    <>
        <Helmet>
            <title>Unauthorized</title>
            <meta name="Unauthorized" content="Unauthorized" />
        </Helmet>

        <UtilityCloseIframe isIframe={isIframe} parentDomain={parentDomain} />
            
        <Container className=" pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    <p className="mt-4"><i className="fas fa-exclamation-triangle text-danger mr-2" />Access Denied.</p>
                    <p className="text-sm font-weight-bold">
                        <span className="text-underline">We take your security seriously.</span>
                        <i className="fas fa-lock ml-3 text-dar" /> 
                    </p>

                    <p className="text-sm">If you are on this page it means you have been given an incorrect link or did not following the the exact directions to pull your auto policy.</p>

                    <p className="text-sm">To proceed please click on your invitation link in either your text messages or email. If you feel this page has been shown in error you may try refreshing your page.</p>

                </Col>
            </Row>
        </Container>

    </>

)
export default UtilityAccessDenied;