/*
Documentation

this page makes the user confirm the company they are logging into is correct

*/

import { useState } from 'react';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Form, Row, Input, FormGroup } from "reactstrap";

import VerificationCompanyIcon from '../_images/VerificationCompany.svg'

import _contacts from '_functions/contacts';

import ModalToggler from 'components/functional/modals/Toggler'

import ModalTerms from './ModalTerms';

const VerificationCompany = ({foundCompany, foundContact, onCompanyVerified, identifier}) => {

    const [email, setEmail] = useState('');
    const [agree, setAgree] = useState(false);
    const [err, setErr] = useState(false);

    const onVerify = async (e) => {

        e.preventDefault();

        if(!agree) return setErr('Please agree to the terms of service before proceeding.')

        if(foundContact._id) return onCompanyVerified()

        const verified = await _contacts.findOrCreate({
            identifier: email, 
            company: foundCompany._id 
        })
        if(!verified.data) return setErr('Please enter a valid email address.')
        onCompanyVerified(verified.data);
    }

    return (

        <>
        <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
    
                    <Form role="form">
    
                        <Card className="bg-white border-0 mb-0 position-relative" >
    
                            <img src={VerificationCompanyIcon} alt="..." className="flow-icon bg-white" />
    
                            <CardHeader>
                                <CardTitle className="mb-0">Let's Get Your Policy!</CardTitle>
                            </CardHeader>

                            <CardHeader>
                                <p className="mb-0">Linking your auto policy only takes a minute and will be required by your attorney before they can accept your case.</p>
                            </CardHeader>
    
                            {/* <CardHeader>
                                <p className="">Linking your auto policy only takes about a minute and will be required by your attorney in your personal injury case.</p>
                                <p className="mb-0">After you accept the accept the <b>Terms Of Service</b> <span className="text-underline cursor-pointer"> <ModalToggler component={ModalTerms}> listed here</ModalToggler></span> and link your policy you'll be one step closer to getting paid!</p>

                            </CardHeader> */}
    
                            {!identifier ? (
                                <CardHeader>
                                    <FormGroup>
                                        <label className="form-control-label">Enter Your Email Address</label>
                                        <Input 
                                            type="text"
                                            value={email}
                                            invalid={err === 'Please enter a valid email address.' ? true : false}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <p className="text-sm mb-0">Don't worry, we will never send you spam or sell your information.</p>
                                    </FormGroup>
                                </CardHeader>
                            ) : null}

                            <CardHeader>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="confirm-terms-of-service"
                                        type="checkbox"
                                        checked={agree}
                                        onChange={(e) => setAgree(!agree)}
                                    />
                                    <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                                        I Agree To The Terms Of Service <span style={{top: -4}} className="text-underline cursor-pointer"> <ModalToggler component={ModalTerms}> listed here</ModalToggler></span>
                                    </label>
                                </div>
                            </CardHeader>

                            {err ? (
                                <CardHeader>
                                    <p className="text-sm font-weight-bold text-warning mb-0">{err}</p>
                                </CardHeader>
                            ) : null}
    
    
                            <CardFooter className="text-center">
                                <button id="loginButton" onClick={onVerify} className="my-2 btn-lg btn-block btn btn-success">
                                    Get My Policy
                                </button>
                            </CardFooter>
                        </Card>

                    </Form>
    
                </Col>
            </Row>
        </Container>

        </>
    )

}

export default VerificationCompany;