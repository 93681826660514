import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row, CardBody } from "reactstrap";

const UtilityError = () => (
    <Container className="mt--9 pb-5 text-center">
        <Row className="justify-content-center">
            <Col lg="5" md="7">
                <Card className="bg-white border-0 mb-0 position-relative text-center" >

                    <CardHeader>
                        <CardTitle className="mb-0">Something's Not Right</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <i className="fas fa-info-circle text-warning display-1 mb-0 ppy-6 " />
                    </CardBody>

                    
                    <CardFooter>
                        <p className="text-left text-sm mb-0">It looks like we are having technical difficulties right now.</p>
                        <p className="mb-0 text-left text-sm">Please check back in a few minutes or try refreshing your page.</p>
                    </CardFooter>

                </Card>
            </Col>
        </Row>
    </Container>
)

export default UtilityError;